.form-control {
	color: #333;
}

.custom-file-label {
	opacity: 0;
}

.custom-file-input {
	opacity: 1;
}

label:not(.form-check-label):not(.custom-control-label).required,
legend.required,
p.required {
	&:after {
		content: " *";
		color: red;
		font-weight: bold;
	}
}

// label.custom-control-label.required {
//     &:after {
//         content: none;
//     }
// }

// Select2 bootstrap
.select2-container--bootstrap {
	.select2-selection--single {
		height: calc(1.5em + 0.75rem + 5px) !important;

		&:focus {
			color: #7b8a8b;
			background-color: #fff;
			border-color: #597ea2;
			outline: 0;
			box-shadow: 0 0 0 0.2rem rgba(44, 62, 80, 0.25) !important;
		}
	}

	.select2-dropdown {
		box-shadow: none;
		border-color: #bbb !important;
	}

	.select2-selection--multiple {
		.select2-selection__choice {
			color: #fff;
			background-color: $secondary;
			border-color: $secondary;
		}

		.select2-selection__choice__remove {
			color: #fff;
		}
	}

	// Survol
	.select2-results__option--highlighted[aria-selected] {
		background-color: $primary;
	}

	// Déjà sélectionné
	.select2-results__option[aria-selected="true"] {
		background-color: #ddd;
	}

	.select2-results__group {
		font-size: $font-size-base;
		color: #fff;
		background: $secondary;
	}
}

.select2-selection__rendered {
	font-size: 1.125rem;
	color: #333 !important;
	// font-weight: bolder;
}

.form-estimation,
.form-insert-property {
	margin-top: 30px;

	.form-section {
		font-size: 24px;
		font-weight: bold;
		// margin-top: 50px;
		margin-bottom: 10px;
	}

	.form-check-custom {

		input[type="radio"],
		input[type="checkbox"] {
			clip: rect(1px, 1px, 1px, 1px);
			position: absolute !important;

			&:checked+label {
				// background: red;
				// border: 2px solid transparent;
				background-color: $primary;
				color: #fff !important;
			}

			&.has-error+label {
				border: 2px solid $danger;
			}
		}

		.form-check {
			display: inline-block;
			padding-left: 0;
			margin-right: 5px;
			margin-bottom: 5px;
		}

		label {
			display: inline-block;
			padding: 10px 15px;
			color: #000;
			background-color: #ddd;
			border: 1px solid transparent;
			border-radius: 3px;

			&:hover {
				cursor: pointer;

				@include media-breakpoint-up(lg) {
					color: #fff !important;
					background-color: $primary;
				}
			}
		}

		&.check-type {

			input[type="radio"],
			input[type="checkbox"] {
				&:checked+label {
					color: #000 !important;
					border: 2px solid $primary;
					background: #fff;
				}
			}

			label {
				background: none;
				border: 1px solid transparent;
				text-align: center;
				margin: 10px;

				i {
					padding: 10px;
					margin-bottom: 5px;
					font-size: 68px;

					@include media-breakpoint-down(sm) {
						font-size: 40px;
					}
				}

				&:hover {
					@include media-breakpoint-up(lg) {
						color: #000 !important;
						// border-color: $primary;
						box-shadow: 0 0 15px 0 #ccc;
					}
				}
			}
		}
	}
}

.form-estimation {

	input,
	select,
	textarea {
		transition: border 0.5s;

		&.has-error {
			border: 1px solid $danger !important;
			box-shadow: 0 0 2px $danger;
		}
	}

	// Select 2
	select.has-error {
		&+span.select2 {
			border: 1px solid $danger !important;
			box-shadow: 0 0 2px $danger;
		}
	}

	#estimate_surface_help {
		color: $danger !important;
	}
}

// Transition des étapes
body:not(.is-admin) {
	.form-estimation {
		.step {
			opacity: 0.3;
			transform: translateY(20px);
			transition: all 0.75s;

			&.is-active {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
}

.property-picture,
.estimate-picture {
	position: relative;

	img {
		border-radius: 3px;
		box-shadow: 0 0 10px rgba(#000, 0.3);
	}

	.btn-delete {
		position: absolute;
		top: -10px;
		right: 5px;
		border-radius: 50%;
	}
}

.form-insert-property {

	#block_property_advantage_0,
	#block_property_distribution_0,
	#block_property_owner_0 {
		button {
			display: none;
		}
	}

	.bg-light-primary {
		background-color: lighten($primary, 45%) !important;
		border: none;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-left: 3px solid transparent;
		transition: all 0.5s;
		margin-bottom: 3rem;

		&:hover {
			background-color: lighten($primary, 40%) !important;
			border-left: 3px solid $primary;
			// box-shadow: 0 0 15px rgba(#000, .125);
		}
	}

	#property_conditions_help {
		display: inline;
		color: #000 !important;
		font-size: 18px;
		font-weight: 400;
		padding-left: 3px;
	}
}

.form-partners,
.form-simulation-pub {
	position: relative;

	::placeholder {
		font-size: 15px;
	}

	.icon-input-profession {
		font-size: 22px;
		position: absolute;
		// top: 4px;
		padding-top: 4px;
		right: 15px;
		z-index: 999;

		i {
			color: #555;
		}
	}
}


// Datepicker
.datepicker table tr td {
	border-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 5px;
}

.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today:hover {
	background: #eee;
	border: 1px dashed $primary !important;
}

.datepicker table tr td.new,
.datepicker table tr td.old {
	color: #333;
}

.datepicker table tr td.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active:hover {
	background: $primary !important;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
	color: #ccc;
	// background: #e5e5e5;
	border-radius: 0;
}