[class*="page-estimation"] {
  .site-content {
    padding-bottom: 0;
  }

  .perf {
    .card {
      border: none;
      border-radius: 1.5rem;
      padding: 40px 0;
      box-shadow: 0 0 40px rgba(#000, 0.15);
    }
  }

  .counter {
    padding: 100px 0 150px;
    background: url("/images/estimer-vendre-calcul.svg") 90% 97% no-repeat;
    background-size: 50%;

    @include media-breakpoint-up("sm") {
      padding: 100px 0;
      background: url("/images/estimer-vendre-calcul.svg") 90% 90% no-repeat;
      background-size: 20%;
    }
  }
}

.page-estimer {
  .fa-bullhorn {
    font-size: 18px;
    padding: 10px;
  }
}

.page-inserer-success {
  .card.coord-bancaire {
    background-color: #fff;
    border: 1px dashed #ddd;
    margin: 3rem 0;

    i.fas {
      font-size: 60px;
      color: lighten($primary, 10%);
      // text-shadow: 1px 1px 1px #aaa;
    }

    p {
      line-height: 1.2;
    }
  }
}

.page-nous-rejoindre {
  .site-content {
    padding-top: 0;
    background: url("/images/estimer-vendre-carriere.svg") 75% 98% no-repeat;
    padding-bottom: 300px;
    background-size: 60%;

    @include media-breakpoint-up("sm") {
      background: url("/images/estimer-vendre-carriere.svg") 75% 95% no-repeat;
      padding-bottom: 100px;
      background-size: 30%;
    }
  }

  .banner {
    padding: 50px 0;
  }

  .job {
    &+.job {
      margin-top: 5rem;


      .container {
        border-top: 1px dashed #ccc;
        padding-top: 5rem;
      }
    }
  }
}