body.is-admin {
	font-size: 0.9375rem !important;
	background-color: #eee;

	h1,
	.h1,
	h2,
	.h2,
	h3,
	.h3,
	h4,
	.h4,
	h5,
	.h5,
	h6,
	.h6 {
		color: #222;
		text-transform: none;
	}

	h1 {
		font-size: 32px;
	}

	.tooltip-inner {
		box-shadow: none;
		color: #fff;
		padding: 2px 5px;
		background-color: #000;
	}

	.arrow {
		&::before {
			border-color: transparent;
			border-top-color: #000;
		}
	}

	.navbar {
		border-bottom: 3px solid $primary;

		.navbar-brand img {
			height: 50px;
		}
	}

	.nav-link {
		color: #fff !important;
		font-weight: normal;

		&.active,
		&:hover {
			color: $primary !important;
		}
	}

	// Sous nav
	li.dropdown:hover>.dropdown-menu {
		background-color: #343a40;
	}

	.dropdown-item {
		color: #fff;
	}

	.pagination {
		margin: 1rem 0 2rem;

		nav {
			margin: 0 auto;

			.page-link:hover {
				color: $primary;
			}

			.page-item {
				&.active .page-link {
					background-color: $primary;
					border-color: $primary;
				}
			}

		}
	}

	.site-footer {
		color: #aaa;
		padding: 0 20px;
		background: none;
	}

	.card {
		border-radius: 5px;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14);
	}

	.card-header {
		font-size: 18px;
		font-weight: bold;

		i {
			margin-right: 5px;
		}
	}

	.table-borderline {
		td {
			border: none;
		}

		tr+tr {
			border-top: 1px solid $border-color;
		}
	}

	.page-estimation-details {

		td:nth-child(1),
		th:nth-child(1) {
			padding-left: 10px;
		}

		td:nth-child(2),
		th:nth-child(2) {
			text-align: right;
			padding-right: 10px;
		}

		@include media-breakpoint-down(sm) {
			.infos-calculs {
				order: 2;
			}

			.infos-propriete {
				order: 1;
			}
		}
	}

	.page-property-details {
		a:not(.btn) {
			font-weight: bold;
		}

		@include media-breakpoint-down(sm) {
			.infos-property {
				order: 2;
			}

			.infos-contact {
				order: 1;
			}
		}
	}

	.list-documents {
		padding: 0;

		li {
			list-style: none;
		}

		a {
			color: #333;
		}
	}

	.form-estimation {

		// masque les infos pour le formulaire côté admin 
		.txt-infos {
			display: none !important;
		}

		[data-action="next"],
		[data-action="create"] {
			display: none;
		}
	}

	.company-logo {
		margin-right: 0;
	}

	.display-filters {
		color: #333;
	}

	.filters {
		background-color: #e5e5e5;
		margin-top: 0.5em;
		margin-bottom: 2em;
	}

	// section formulaire edition région pub
	.assoc-company-profession {
		.form-row {
			margin-bottom: 10px;
			border: 1px solid #ddd;

			&.is-booked {
				border-left: none;

				.assoc-profession {
					border-left: 8px solid $primary;
					background-color: grey;
				}
			}

			&>div {
				padding: 5px;
			}
		}

		// Surligné la ligne pointé par l'ancre
		:target+.form-row {
			background: lighten($primary, 40%);
		}

		.flex-content {
			display: flex;
			flex-flow: column wrap;
			justify-content: center;
			align-items: center;

			@include media-breakpoint-down("md") {
				flex-flow: row wrap;
				justify-content: space-between;
			}
		}

		.assoc-profession {
			color: #fff;
			background-color: #c5c5c5;
			padding: 5px;

			strong {
				text-align: center;
				font-size: 16px;
				font-weight: bold;
				text-transform: uppercase;
			}
		}

		select:disabled {
			padding: 0;
			background: none;
			border: none;
			-webkit-appearance: none;
		}

		select::-ms-expand {
			display: none;
		}
	}

	.contract-content {
		border-left: 1px dashed #ddd;
	}

	.select2-selection__rendered {
		font-size: 0.85rem;
	}
}