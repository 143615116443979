.btn-special {
    border-color: $primary;
    color: $primary;
	background: transparent;
	&:hover {
	   color: #fff;
       background-color: $primary;
	}
}

.btn-footer {
	border-radius: 0;
	width: 100%;
	text-align: center;
}

.btn-delete {
	padding: 0;
	height: 20px;
	width: 20px;
	// line-height: 16px;
	border-radius: 50%;
	color: #fff;
	background-color: $danger;
	font-size: 12px;

	&:hover {
		color: #fff;
	}
}

.btn.disabled,
.btn:disabled {
	opacity: 0.25;
}