.reveal-loaded {
    [data-reveal] {
        opacity: 0;
    }

    [data-reveal="fade-left"] {
        transform: translate3d(100px, 0, 0);
    }

    [data-reveal="fade-right"] {
        transform: translate3d(-100px, 0, 0);
    }

    [data-reveal="fade-up"] {
        transform: translate3d(0, 100px, 0);
    }

    [data-reveal="fade-down"] {
        transform: translate3d(0, -100px, 0);
    }

    [data-reveal="zoom-out"] {
        transform: scale(1.2);
    }

    [data-reveal="zoom-in"] {
        transform: scale(.8);
    }

    .reveal {
        opacity: 1;
        // transition: 1s cubic-bezier(.5, 0, 0, 1);
        transition: 1s ease;
        transition-property: opacity, transform;

        &[data-reveal="fade-left"],
        &[data-reveal="fade-right"],
        &[data-reveal="fade-down"],
        &[data-reveal="fade-up"] {
            transform: none;
        }

        &[data-reveal="zoom-out"],
        &[data-reveal="zoom-in"] {
            transform: scale(1);
        }

        $times: 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000;

        @each $time in $times {
            &[data-reveal-delay="#{$time}"] {
                transition-delay: $time / 1000 + s;
            }
        }

        $times: 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000;

        @each $time in $times {
            &[data-reveal-duration="#{$time}"] {
                transition-duration: $time / 1000 + s;
            }
        }
    }

}

@media (prefers-reduced-motion: reduce) {
    [data-reveal] {
        opacity: 1;
    }

    [data-reveal*="fade"],
    [data-reveal*="zoom"] {
        transform: none;
    }
}