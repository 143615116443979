.page-front {

  .site-content {
    padding: 0;
  }


  section.activities {
    margin-top: 10px;

    .card {
      border-radius: 1rem;
      box-shadow: 0 0 20px rgba(#000, 0.125);
      transition: $transition-base;

      &:hover {
        transform: translateY(-10px);
        background: #FBE7B0;
      }
    }

    .card.activity-pub {
      &:hover {
        background: #e1ebfa;
      }
    }

    .activity-estimate {
      img {
        width: 64%;
      }
    }

    .btn-pub {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  section.estimate {
    @include media-breakpoint-up('lg') {
      margin-top: 100px;
    }

    .illustration {
      img {
        width: 100%;

        @include media-breakpoint-up('lg') {
          position: absolute;
          top: -40px;
          right: -40px;
        }

        @include media-breakpoint-up('xl') {
          top: -80px;
          right: -80px;
          width: 85%;
        }
      }
    }
  }

  section.sale {
    .services {
      .card {
        border: none;
        border-radius: 1rem;
        transition: $transition-base;

        &:hover {
          box-shadow: 0 5px 15px rgba(#000, 0.15);
        }
      }
    }
  }

  section.contact {
    h2 {
      color: #000;
      font-weight: bold;
    }

    p {
      margin: 50px 0;
    }

    .btn {
      margin: 10px;
    }

    .contact {
      height: 100%;
      padding: 35px;
      color: #fff;
      line-height: 2;
      font-weight: bold;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      // background: url("/images/contact-home.jpg") 50% 50% no-repeat;
      // background-size: cover;

      a {
        font-weight: bold;
        color: #fff;
      }
    }
  }
}