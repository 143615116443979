h1,
h2 {
	text-transform: uppercase;
}

h2.baseline {
    @extend .h4; 
    font-weight: normal;
    color: $body-color;
    text-transform: initial;
}