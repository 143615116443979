.js-carousel {
	.slick-track {
		padding: 20px 0;
	}

	.slick-arrow {
		z-index: 100;
		// background-color: #333 !important;
		// color: #fff;
	}

	.slick-next,
	.slick-prev {
		&:before {
			color: #333;
			font-size: 35px;
		}
	}

	.slick-next {
		right: -10px;
	}

	@include media-breakpoint-down(sm) {
		.slick-next {
			right: 10px;
		}

		.slick-prev {
			left: 0;
		}
	}

	.slick-dots li button::before {
		font-size: 10px;
	}
}

.js-carousel-properties {
	.card {
		transition: all 0.3s;

		&:hover {
			transform: scale(1.05);
		}

		h3 {
			color: #999;
		}

		h3,
		h4 {
			margin-bottom: 0;
		}
	}
}

.js-carousel-professions {
	@include media-breakpoint-down(sm) {
		.slick-track {
			// top 30px pour voir l'effet hover
			padding: 30px 0 20px;
		}

		.slick-next,
		.slick-prev {
			z-index: 10;

			&:before {
				color: #aaa;
				font-size: 25px;
			}
		}

		.slick-next {
			right: 10px;
		}

		.slick-prev {
			left: 0;
		}
	}
}

.js-carousel-companies {
	.slick-track {
		// top 30px pour voir l'effet hover
		padding: 30px 0 20px;
	}
}