.invoice-list {
	padding: 0;
	font-size: 13px;

	table {
		margin-bottom: 0;
	}
}

.card-contract {
	font-size: 13px;

	ol {
		margin: 0;
		padding-left: 15px;
	}

	p {
		margin-bottom: 0.5rem;
	}
}