// $enable-shadows: true;

// $body-bg: #111;
// $body-color: #fff;

// Colors
$primary: #f1b329;
$pub: #2060BC;
$success: #4eaf6b;
$danger: #fa554a;
$info: #0a91df;
$theme-colors: (
	"primary": $primary,
	"primary-light": lighten($primary, 30%),
	"secondary": #555,
	"danger": #fa554a,
	"pub": #2060BC,
	"orange": #f36d00,
	"grey": #e5e5e5,
);

$link-color: darken($primary, 5%);
$link-hover-color: darken($link-color, 5%);

$font-family-base: "Roboto",
	sans-serif;
$headings-font-family: "Montserrat",
	sans-serif;
$font-size-base: 1.125rem;
$font-weight-normal: 300;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$h1-font-size: $font-size-base * 2.25;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1;

$headings-font-weight: 700;
$headings-color: $primary;
$headings-margin-bottom: 2rem;

// Paragraphs
$paragraph-margin-bottom: 2rem;


// Buttons
.btn {
	font-family: "Montserrat";
}

.btn-primary,
.btn-primary-light {
	color: #fff !important;
}

$btn-border-radius: 30px;
$btn-border-radius-lg: 30px;

$btn-padding-x: 2rem;
$btn-padding-y: 0.5rem;
$btn-padding-x-lg: 2rem;
$btn-padding-y-lg: 0.75rem;

// Navbar
$navbar-light-color: #555;
$navbar-light-hover-color: darken($primary, 5%);
$navbar-light-active-color: darken($primary, 5%);

// Dropdowns
$dropdown-min-width: 15rem !default;
$dropdown-padding-y: 0;
// $dropdown-spacer: .125rem !default;
$dropdown-bg: #fff !default;
$dropdown-link-color: #555;
$dropdown-link-hover-color: darken($primary, 5%);
$dropdown-link-active-color: darken($primary, 5%);

$dropdown-link-hover-bg: none;
$dropdown-link-active-bg: none;
$dropdown-divider-bg: #555;
$dropdown-padding-y: 0;
$dropdown-item-padding-x: 0.25rem;
// $dropdown-item-padding-y: 0;


// $input-btn-focus-width: 0.1rem;
$component-active-bg: #ccc;
// $component-active-bg: $primary;

// Forms
$input-placeholder-color: #aaa;
// $custom-control-indicator-checked-color: $primary;
$custom-control-indicator-checked-bg: $primary;
$custom-control-indicator-focus-box-shadow: 0 0 0 1px #fff,
	$primary;


// Alerts
$alert-margin-bottom: 2rem;


// Tooltips
$tooltip-arrow-color: #fff;
$tooltip-max-width: 420px;
$tooltip-padding-y: 15px;
$tooltip-padding-x: 15px;
$tooltip-color: #000;
$tooltip-bg: #fff;

$popover-max-width: 420px;

// Cards
// $card-border-radius: 2rem;

// Breadcrumb
$breadcrumb-bg: transparent;
$breadcrumb-padding-x: 0;
$breadcrumb-divider: "»";