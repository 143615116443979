.navbar {
    font-size: 16px;
    position: relative;
    z-index: 10;
    transition: .3s padding;

    .navbar-brand {
        padding: 0;

        img {
            height: 50px;
            width: auto;
            @include media-breakpoint-up('sm') {
                height: 80px;
            }
        }
    }

    .navbar-collapse {
        flex-grow: 0;
        // padding: 20px;

        @include media-breakpoint-up('sm') {
            padding: 0;
        }

    }

    .dropdown-menu {
        font-size: 16px;
        padding: 1.5rem;
    }

    .navbar-toggler {
        border: none;

    }

    .dropdown-toggle::after {
        // remove normal borders
        border: none !important;
        // font awesome icon
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f107";
        // size & position
        font-size: 12px;
        padding: 0 3px;
        /* to center vertically */
        vertical-align: 0;
    }
}

// Fixer le navbar au scroll sauf sur le formulaire d'estimation
body:not(.page-estimer) {
    .site-navbar.sticky {
        transform: translateY(0);
        position: fixed;
        z-index: 1040;
        background: #fff;
        width: 100vw;
        box-shadow: 0 10px 20px 0 rgba(#000, 0.125);
        .navbar-brand {
            padding: 0;
    
            img {
                height: 50px;
                width: auto;
                @include media-breakpoint-up('sm') {
                    height: 70px;
                }
            }
        }
    }
}

.navbar-light {
    .navbar-nav {
        .nav-link {
            font-weight: 500;
            color: #555;

            &.active,
            &:hover {
                color: $primary;
            }
        }
    }
}


// Sous nav
li.dropdown:hover>.dropdown-menu {
    border: none;
    background-color: $dropdown-bg;
    box-shadow: 0 0 15px rgba(#000, .125);
    display: block;

    a {
        font-weight: 500;
    }
}

.dropdown-item {

    &.active,
    &:hover,
    &:focus {
        color: $primary;
    }
}