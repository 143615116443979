.site-footer {
    color: #ccc;
    background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
        url("/images/bg-footer.jpg") center center / cover no-repeat;
    // padding: 75px 0 25px;
    margin-top: 50px;

    @include media-breakpoint-down(sm) {
        .row {
            flex-direction: column-reverse;
        }

        nav {
            text-align: center;
        }
    }

    .activities {
        li i {
            color: #2060BC;
        }
        .btn {
            border: none;
            background-color: #2060BC;;
        }
    }

    .h3 {
        font-size: 22px;
        padding: 0;
        margin-bottom: 15px;
        color: #ccc;

        @include media-breakpoint-up(md) {
            text-align: left;
        }
    }

    a {
        color: #ccc;
    }

    nav {
        padding: 0;

        &>ul {
            margin-bottom: 30px;
        }

        @include media-breakpoint-up(sm) {
            &>ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 0;
            }
        }

        ul {
            margin-bottom: 10px;
            padding: 0;
        }
    }

    li {
        list-style: none;
        font-size: 16px;
        margin: 0;
        padding: 0;
    }

    .contact {
        text-align: center;

        img {
            display: block;
            width: 150px;
            height: auto;
            text-align: center;
            margin-bottom: 20px;
        }

        @include media-breakpoint-up(sm) {
            display: flex;
            text-align: left;
            margin: 30px 0;

            img {
                margin-right: 20px;
                margin-bottom: 0;
                width: 200px;
                height: auto;
            }
        }

        @include media-breakpoint-up(md) {
            margin: 0;
            flex-direction: column;
            align-items: flex-end;
            text-align: right;

            img {
                margin-right: 0;
                margin-bottom: 5px;
            }
        }

        .coordonnees {
            font-size: 14px;
            margin-bottom: 0;
        }
    }

    .footer-bottom {
        text-align: center;
        border-top: 1px solid #666;
        font-size: 14px;
        color: #999;
        padding-top: 15px;
    }

    @include media-breakpoint-up(sm) {
        .copyright {
            text-align: left;
        }

        .realisation {
            text-align: right;
        }
    }
}