@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700|Roboto:300,400,700&display=swap");

.site-content {
	padding: 50px 0 100px;
}

.alert {
	p {
		margin: 0;
	}
}

.highlight {
	font-size: 20px;
}

.tooltip-inner {
	text-align: left;
	box-shadow: 0 0 20px 0 rgba(#000, 0.3);
}

.popover {
	box-shadow: 0 0 20px 0 rgba(#000, 0.3);
}

.badge-sm {
	font-size: 20px;
}

.avatar {
	border-radius: 50%;
}

.avatar-text {
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: center;
	font-weight: bold;
}

.avatar-xxs {
	width: 20px;
	height: 20px;
	font-size: 12px;
}

.avatar-xs {
	width: 28px;
	height: 28px;
}

.avatar-sm {
	width: 60px;
	height: 60px;
}

.avatar-md {
	width: 75px;
	height: 75px;
}

.avatar-lg {
	width: 120px;
	height: 120px;
}

// PICTO
[class*="fa-"] {
	&.medium {
		font-size: 20px;
	}

	&.big {
		font-size: 24px;
	}

	&.round {
		background-color: #fff;
		padding: 10px 18px;
		border: 1px solid $secondary;
		border-radius: 50%;
	}
}

@include media-breakpoint-down(sm) {
	h1 {
		font-size: $font-size-base * 1.75;
	}

	h2 {
		font-size: $font-size-base * 1.35;
	}
}

.card-shadow {
	border: none;
	box-shadow: 0 0 15px rgba(#000, 0.125);
}

.card-body {
	p:last-child {
		margin-bottom: 0;
	}
}

.text-normal {
	text-transform: initial;
}

.fa-bullhorn {
	position: relative;
	color: $primary;
	transform: rotate(-25deg);
	border: 3px solid lighten($primary, 15%);
	border-radius: 50%;
	padding: 15px;
}


ul.list-website {
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		display: inline-block;
	}
}

.col-icon {
	display: flex;
	justify-content: center;
	align-self: center;
}

.underline {
	text-decoration: underline;
	text-decoration-style: solid;
}

.question {
	cursor: help;
}

.blink {
	animation: blink 3s ease-in-out infinite alternate;
}

.anchor-navbar-fixed {
	margin-top: -85px;
	padding-top: 85px;
	display: inline-block;
}

.badge-group {
	display: inline-flex;
	vertical-align: middle; // match .btn alignment given font-size hack above

	>.badge:first-child {
		margin-left: 0;
	}

	// Reset rounded corners
	>.badge:not(:last-child):not(.dropdown-toggle),
	>.badge-group:not(:last-child)>.badge {
		@include border-right-radius(0);
	}

	>.badge:not(:first-child),
	>.badge-group:not(:first-child)>.btn {
		@include border-left-radius(0);
	}
}

.modal {

	&.profession-explain {
		color: #333;

		ul {
			padding-left: 0;
		}

		li {
			list-style-type: none;
			margin-bottom: 20px;
		}

		.modal-header {
			border-bottom: none;
		}

		.modal-title {
			color: #333;
		}
	}

	&.video {
		.modal-dialog {
			max-width: 800px;
			margin: 30px auto;
		}
		.modal-content {
			background: none;
			border: none;
		}
		.modal-body {
			padding: 0;
		}
		.close {
			color: #fff;
		}
	}
}


.sticky-top {
	top: 100px;
}