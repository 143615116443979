.bg {
  background-color: #f5f5f5;
  padding: 25px 10px;
}

.container-email {
  font-size: 14px;
  width: 600px;
  margin: 10px auto;

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    color: #222;
    text-transform: none;
  }

  .warning-info {
    text-align: justify;
    line-height: 1.2;
  }

}

.border-dashed {
  border-style: dashed;
}